import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

export function configFirebase() {
  const firebaseConfig = {
    apiKey: 'AIzaSyDINTRxbHGIQzXMOpW1uSUCAT4-rhWe8bw',
    authDomain: 'musewallet-f684f.firebaseapp.com',
    projectId: 'musewallet-f684f',
    storageBucket: 'musewallet-f684f.appspot.com',
    messagingSenderId: '971094102674',
    appId: '1:971094102674:web:5e10e95043d1af1c228c4b',
    measurementId: 'G-NKLKGK3END'
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app)
  window.firebaseApp = app;
  window.firebaseAnalytics = analytics;

  window.fbLogEvent = fbLogEvent
}

export function fbLogEvent(eventName, eventParams) {
  console.log('fbLogEvent:', eventName)
  const params = {
    'origin': 'OpenOfficial',
    'inviteId': eventParams ? eventParams['inviteId'] || 'OpenOfficial_NULL' : 'OpenOfficial_NULL',
    ...eventParams,
    time: Date.now().toString()
  }
  logEvent(window.firebaseAnalytics, eventName, params)
}

