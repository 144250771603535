
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';

import App from './App.vue';
import store from './store';
import router from './router';
import routerH5 from './router/h5';
import { configFirebase } from './utils/firebase'
// import '@/utils/vue-click.js'; // vue click

import messages from './locales';
import '@/index.less';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import vant from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';
// import { NavBar } from 'vant';

const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'zh', // set fallback locale
  messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
});

const app = createApp(App); // 创建实例

app.use(ElementPlus)
app.use(store);
app.use(i18n);
app.use(vant);

const ISMOBILE = function() {
  const userAgent = navigator.userAgent
  return (/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(userAgent))
}

app.use(ISMOBILE() ? routerH5 : router);

app.mount('#app');

configFirebase()
