/**
 * @file 国际化入口，提供语言相关的常用方法
 */

const messages = {
  en: {
    header: {
      personal: 'Individual',
      business: 'Business',
      products: 'Products',
      developers: 'Developers',
      partners: 'Partners',
      company: 'Company',
      partnership: 'Be Promoter',
    },
    personal: {
      slogan: 'Connect your business with Digital assets',
      descList: {
        title1: 'PROFESSIONAL',
        desc1: 'User Centric',
        title2: 'SAFE',
        desc2: 'High level of fund security',
        title3: 'LICENSING',
        desc3: 'Virtual Asset Service Provider ',
        title4: 'DIVERSIFIED',
        desc4: 'Customized VIP privilege ',
        title5: 'SUPPORT',
        desc5: 'Prompt customer service',
      },
      contact: 'Contact Us',
      demo: 'Demo',
      stephenName: 'Curtis Bridgman',
      stephenCeo: 'Owner',
      stephen: ' HPay allows our customers to make purchases anywhere in the world name',
      sellProduct: 'Sell a service or product',
      sellProductDest: 'The MuseWallet system implements encrypted payments on almost any website or store.',
      selectPay: 'Select MuseWallet to pay',
      selectPayDesc: 'Your client can use the cryptocurrency he holds to pay for goods or services.',
      weGenerate: 'Receive funds directly to your bank account with payment invoice',
      weGenerateDesc: 'We calculate the cryptocurrency price of the selected item and provide the consumer with a payment interface.',
      receive: 'Receive your earnings',
      receiveDesc: 'You will receive the cryptocurrency paid by the payer directly or exchange it into another cryptocurrency.',
      receiveFund: 'Receive funds directly to your bank account.',
      participate: 'Participate in a',
      bankDeposits: 'Bank deposits in 30 countries, settled in US Dollars, Euros, GBP, and more.',
      crypto: 'Get settled in fiat or crypto.',
      works: 'Show How It Works',
      billion: '10+ Million Transactions',
      billionDollars: '5+ Billion Dollars',
      support: 'We support 50+ crypto wallets',
      join: 'Join the thousands of businesses already using the world\'s leading crypto payment processor. It\'s free to sign up.',
      participateDesc: 'MuseWallet accepts 10+ cryptocurrencies which account for 80% of the global crypto market cap.',
    },
    business: {
      slogan_part1: 'Accept or Send',
      slogan_part2: 'BTC',
      slogan_part3: 'Payments Globally',
      manage: 'Manage your cryptocurrencies with the HPay application and convert cryptocurrencies into dollars with the HPay card.',
      comingSoon: 'Coming Soon',
      secure: 'Secure your funds with HPay',
      easily: 'Easily manage multiple cryptocurrencies and purchase goods or gift cards.',
      shopHPay: 'with HPay',
      turn: 'Turn your crypto into dollars fast for spending with the HPay Card.',
      shop: 'Shop with HPay merchants online & in-person – around the world.',
    },
    developer: {
      only: 'Only 1% comission for all buttons, plugins, web PoS, APIs and billing.',
      due: '*Due to the congestion in the Ethereum network, a fee of 5 USD equivalent to each outgoing ETH network transaction is applied',
      fee: '*For all other coins and tokens, the minimum amount is 50 USD worth of the currency',
    },
    partners: {
      part: 'Get more visibility - Part of our product marketing efforts will go towards promoting your venture.',
      have: 'Have more use-cases - When listed on HPay, thousands of shops will start accepting payments in your currency.',
    },
    company: {
       about: 'MusePay is an upraising digital fintech company registered as Virtual Asset Service Provider (VASP), providing a world-class infrastructure for digital assets, prepaid credit cards, and payments.\n\nOur primary product, MuseWallet, which enables users to deposit and withdraw cryptocurrencies, is comprehensive Fintech platform. Moreover, MuseEarn is available as a wealth appreciation feature with 2.5% APY, flexible terms, simplicity to use as well as security guaranteed.\n\nBeyond that, we provide credit cards to individuals who are willing to pay a one-off 400 USDT in order to apply for a VIP qualification. The cards are not issued by MusePay, this serves as a voluntary decision.',
       major: 'MuseWallet has established a professional financial technology R&D team of more than 200 people, focusing on the blockchain, cryptocurrency and payment industry',
       technological: 'Adopt advanced technology to provide services for cross-border transactions',
       security: 'MuseWallet strictly abides by laws and regulations to create a safe trading environment for customers',
       innovate: 'According to the business requirements, plug-ins that meet various needs can be developed to realize functions.',
      },
    individual: {
      slogan: 'Accept or Send BTC Payments Globally'
    }
  },
  zh: {
    individual: {
      slogan: '在全球範圍內接受或發送BTC付款'
    }
  }
};

export default messages;
