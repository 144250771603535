import { createRouter, createWebHashHistory } from 'vue-router';

const router = createRouter({
  history: createWebHashHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  scrollBehavior(to, from, savedPosition) {
    if (to.name !== from.name) {
      window.scrollTo(0, 0)
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/',
      redirect: '/individual',
    },
    {
      path: '/individual',
      name: 'individual',
      component: () => import(/* webpackChucompanynkName: "individual" */ '@/views/individual/h5'),
      meta: {
        index: 1,
      },
    },
    // {
    //   path: '/business',
    //   name: 'business',
    //   component: () => import(/* webpackChunkName: "business" */ '@/views/business'),
    //   meta: {
    //     index: 1,
    //   },
    // },
    {
      path: '/developers',
      name: 'developers',
      component: () => import(/* webpackChunkName: "developers" */ '@/views/developers'),
      meta: {
        index: 1,
      },
    },
    {
      path: '/list',
      name: 'list',
      component: () => import(/* webpackChunkName: "list" */ '@/views/list'),
      meta: {
        index: 1,
      },
    },
    {
      path: '/partner',
      name: 'partner',
      component: () => import(/* webpackChunkName: "partner" */ '@/views/partner'),
      meta: {
        index: 1,
      },
    },
    {
      path: '/company',
      name: 'company',
      component: () => import(/* webpackChunkName: "login" */ '@/views/company'),
      meta: {
        index: 1,
      },
      props: {
        router: 'company'
      }
    },
    {
      path: '/partnership',
      name: 'partnership',
      component: () => import(/* webpackChucompanynkName: "individual" */ '@/views/partnership/h5'),
      meta: {
        index: 1,
      },
    },
    {
      path: '/pay',
      name: 'pay',
      component: () => import(/* webpackChucompanynkName: "individual" */ '@/views/open-app/index'),
      meta: {
        index: 1,
      },
    },
    {
      path: '/app',
      name: 'app',
      component: () => import(/* webpackChucompanynkName: "individual" */ '@/views/open-app/index'),
      meta: {
        index: 1,
      },
    },
    {
      path: '/open-app',
      name: 'OpenApp',
      component: () => import(/* webpackChucompanynkName: "individual" */ '@/views/open-app/index'),
      meta: {
        index: 1,
      },
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
    //   meta: {
    //     index: 2,
    //   },
    // },
    // {
    //   path: '/category',
    //   name: 'category',
    //   component: () => import(/* webpackChunkName: "category" */ '@/views/Category.vue'),
    //   meta: {
    //     index: 1,
    //   },
    // },
    // {
    //   path: '/product-list',
    //   name: 'product-list',
    //   component: () => import(/* webpackChunkName: "product-list" */ '@/views/ProductList.vue'),
    //   meta: {
    //     index: 2,
    //   },
    // },
    // {
    //   path: '/product/:id',
    //   name: 'product',
    //   component: () => import(/* webpackChunkName: "product" */ '@/views/ProductDetail.vue'),
    //   meta: {
    //     index: 3,
    //   },
    // },
    // {
    //   path: '/cart',
    //   name: 'cart',
    //   component: () => import(/* webpackChunkName: "cart" */ '@/views/Cart.vue'),
    //   meta: {
    //     index: 1,
    //   },
    // },
    // {
    //   path: '/create-order',
    //   name: 'create-order',
    //   component: () => import(/* webpackChunkName: "create-order" */ '@/views/CreateOrder.vue'),
    //   meta: {
    //     index: 2,
    //   },
    // }
  ],
});

export default router;
